/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XButton from 'vux/src/components/x-button';
import XTextarea from 'vux/src/components/x-textarea';
export default {
  name: 'OutSide',
  components: {
    XTextarea: XTextarea,
    XButton: XButton
  },
  data: function data() {
    return {
      userSerial: '',
      xh: 1002,
      userLx: 0,
      bk: false,
      info: {
        userLname: '',
        userLx: 1,
        userSexName: '',
        userNation: '',
        userBirthday: '',
        userAddress: '',
        jlSj: '',
        doorName: "",
        bodyTemp: "",
        dealBz: ""
      },
      userphotoNull: require('@/assets/userphotonull.png')
    };
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      this.$api.BodyTemp.getMsgInfo({
        xh: this.xh,
        userLx: this.userLx
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.info = data;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.BodyTemp.commitModify({
        xh: this.xh,
        dealBz: this.info.dealBz,
        userLx: this.userLx,
        dealStatus: 1 // isKnow: this.isKnow,

      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.onCancel();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    onCancel: function onCancel() {
      if (this.bk) {
        this.$router.go(-1);
      } else {
        window.WeixinJSBridge.call('closeWindow');
      }
    }
  },
  computed: {},
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem('userSerial');
  },
  mounted: function mounted() {
    this.xh = this.$route.query.xh;
    this.userLx = this.$route.query.userLx;
    this.bk = this.$route.query.bk;
    this.getInfo();
  },
  destroyed: function destroyed() {
    this.$utils.Tools.loadHide();
  },
  watch: {// isKnow(val) {
    //   if (val) {
    //     this.isNotKnow = !val;
    //   } else {
    //     if (!this.isNotKnow) {
    //       this.isKnow = true;
    //     }
    //   }
    // },
    // isNotKnow(val) {
    //   if (val) {
    //     this.isKnow = !val;
    //   } else {
    //     if (!this.isKnow) {
    //       this.isNotKnow = true;
    //     }
    //   }
    // }
  }
};